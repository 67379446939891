import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, FormGroup, Icon, InputGroup, Intent } from '@blueprintjs/core';
import InputFormField from '@components/InputFormField';
import { $workspaceInitData } from '@containers/store/states';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { AuthenticationFormStep } from '../../enums';
import { authenticationFormStepApi } from '../../store/apis';
import { $emailSchema } from '../../store/states';
import Heading from '@components/Heading';
import PasswordLockButton from '@app/components/PasswordLockButton';
import { useLoginViaEmailAndPassword } from '../../hooks';

const passwordValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const SigninForm: React.FC = () => {
    const login = useLoginViaEmailAndPassword();

    const emailSchema = useStore($emailSchema);
    const workspaceInitData = useStore($workspaceInitData);

    const password = useTextFormField({
        id: 'client-password',
        validators: passwordValidators,
        initialValue: '',
    });

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const form = useForm({
        fields: [password],
        apiCall: async () => {
            try {
                await login({
                    email: emailSchema!.email,
                    password: password.value,
                    workspace_id: workspaceInitData?.workspace.id ?? null,
                });
            } catch (e) {
                throw e;
            }

            return { response: true };
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const renderHeader = () => {
        if (workspaceInitData) {
            // Workspace
            return (
                <Heading className="mb-2" type="h3">
                    Login to {workspaceInitData.workspace.key} platform
                </Heading>
            );
        } else {
            // Internal DotYeti platform
            return (
                <Heading className="mb-2" type="h3">
                    Login to DotYeti platform
                </Heading>
            );
        }
    };

    if (!emailSchema) {
        // Email schema is empty return to the email check step
        authenticationFormStepApi.moveToStep(AuthenticationFormStep.EMAIL_CHECK);
    }

    return (
        <Card>
            {renderHeader()}
            <form onSubmit={form.handleFormSubmit}>
                <FormGroup className="mb-1" label="Email">
                    <InputGroup
                        fill
                        disabled
                        placeholder="Enter email"
                        value={emailSchema?.email ?? ''}
                        rightElement={
                            <Button
                                minimal
                                tabIndex={-1}
                                icon={<Icon icon="edit" size={24} />}
                                onClick={() => authenticationFormStepApi.moveToStep(AuthenticationFormStep.EMAIL_CHECK)}
                            />
                        }
                    />
                </FormGroup>

                <InputFormField
                    field={password}
                    formGroupProps={{ label: 'Password' }}
                    inputProps={{
                        placeholder: 'Enter password',
                        type: isPasswordVisible ? 'text' : 'password',
                        rightElement: (
                            <PasswordLockButton
                                isPasswordVisible={isPasswordVisible}
                                changePasswordVisibility={setIsPasswordVisible}
                            />
                        ),
                    }}
                />

                <Button
                    fill
                    loading={form.isSubmitting}
                    disabled={form.hasFieldErrors}
                    className="mt-1"
                    type="submit"
                    intent={Intent.PRIMARY}
                >
                    Login
                </Button>
            </form>
        </Card>
    );
};

export default SigninForm;
