import { fetchAuthorizedUserPermissions } from '@app/containers/store/effects';
import { LocalStorage } from '@app/data/enums';
import { ToastUtils } from '@app/data/utils';
import { useFetchAuthorizedUser, useLogout } from '@app/hooks';
import { Intent } from '@blueprintjs/core';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { SignInAttemptError } from 'dy-frontend-http-repository/lib/modules/Auth/enums';
import { AuthenticateWithEmailAndPasswordInput } from 'dy-frontend-http-repository/lib/modules/Auth/inputs';
import { SignInAttemptResultResource } from 'dy-frontend-http-repository/lib/modules/Auth/resources';
import { authenticateViaEmailAndPassword } from '../../store/effects';

const useLoginViaEmailAndPassword = () => {
    const logout = useLogout();
    const fetchAuthorizedUser = useFetchAuthorizedUser();

    const handleAttemptError = (attempt: SignInAttemptResultResource) => {
        switch (attempt.error) {
            case SignInAttemptError.AUTH_METHOD_NO_SUPPORT:
                ToastUtils.showToast({ message: 'Authentication method is not supported', intent: Intent.DANGER });
                break;
            case SignInAttemptError.CREDENTIALS_INVALID:
                ToastUtils.showToast({ message: 'Invalid email or password provided', intent: Intent.DANGER });
                break;
            case SignInAttemptError.IDENTITY_MISSING:
                ToastUtils.showToast({
                    message: 'This account is not associated with the platform',
                    intent: Intent.DANGER,
                });
                break;
            case SignInAttemptError.IDENTITY_SHUTDOWN:
                ToastUtils.showToast({
                    message: 'Your account is disabled',
                    intent: Intent.DANGER,
                });
                break;
            case SignInAttemptError.INTERNAL:
                ToastUtils.showToast({
                    message: 'Error occurred, try again',
                    intent: Intent.DANGER,
                });
                break;
        }
    };

    const handleCreateSession = async (attempt: SignInAttemptResultResource) => {
        if (attempt.session) {
            localStorage.setItem(LocalStorage.ACCESS_TOKEN, attempt.session.access_token);
            repository.setAuthAccessTokenHeader(attempt.session.access_token);

            try {
                await fetchAuthorizedUserPermissions();
                await fetchAuthorizedUser();
            } catch (e) {
                // TODO: handle error
                logout();
                throw e;
            }
        } else {
            ToastUtils.showToast({
                message: 'Error occurred while creating your session, try again',
                intent: Intent.DANGER,
            });
        }
    };

    const handleLogin = async (input: AuthenticateWithEmailAndPasswordInput) => {
        try {
            // TODO: add workspace_id if necessary
            const attempt = await authenticateViaEmailAndPassword(input);

            if (attempt.error) {
                handleAttemptError(attempt);
            } else {
                await handleCreateSession(attempt);
            }
        } catch (e) {
            throw e;
        }
    };

    return handleLogin;
};

export default useLoginViaEmailAndPassword;
