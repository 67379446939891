import { $isAuthorized } from '@app/containers/store/states';
import { LocalStorage } from '@app/data/enums';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { useStore } from 'effector-react';
import moment from 'moment';
import { useEffect, useRef } from 'react';

// 3 minutes
const presencePingIntervalDelayInMs = 1000 * 60 * 3;

const usePresencePing = () => {
    const isAuthorized = useStore($isAuthorized);

    const presencePingInterval = useRef<NodeJS.Timeout | null>(null);

    const handlePing = async () => {
        if (!isAuthorized) {
            return;
        }

        const now = moment();
        const lastPingAt = localStorage.getItem(LocalStorage.LAST_PRESENCE_PING_AT);

        // Check if should ping
        if (lastPingAt !== null) {
            // Already pinged before
            if (now.diff(moment(lastPingAt), 'milliseconds') < presencePingIntervalDelayInMs) {
                // Do not ping
                return;
            }
        }

        try {
            await repository.me().notifyAboutUserPresence();
            localStorage.setItem(LocalStorage.LAST_PRESENCE_PING_AT, moment().utc().format());
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    useEffect(() => {
        // Clear ping interval if present
        if (!isAuthorized && presencePingInterval.current !== null) {
            clearInterval(presencePingInterval.current);
            return;
        }

        // Set ping interval
        if (isAuthorized && presencePingInterval.current === null) {
            // Do initial ping
            handlePing();

            // Set interval
            presencePingInterval.current = setInterval(async () => {
                handlePing();
            }, presencePingIntervalDelayInMs);
        }
    }, [isAuthorized]);
};

export default usePresencePing;
