import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { resetTeamOccupancy, resetTeams } from './events';
import domain from './domain';
import { TeamOccupancyResource } from 'dy-frontend-http-repository/lib/modules/TeamOccupancy/resources';

// Teams
export const $teams = domain.createStore<CollectionResource<TeamResource, {}> | null>(null).reset(resetTeams);

// Team occupancy
export const $teamOccupancy = domain
    .createStore<CollectionResource<TeamOccupancyResource, {}> | null>(null)
    .reset(resetTeamOccupancy);
